<template>
  <DashboardLayout>
    <LoadingSpinner :isLoading="isLoading" />
    <ToastManager ref="toastManager" />
    <div class="careseeker-container">
      <div>
        <div>
          <div class="notice">
            <div class="caregivers-list">
              <div class="caregivers-list-individual">
                <div class="caregivers-list-first">
                  <div class="header-container">
                    <div class="profile-image-container"></div>
                    <div class="header-container-details">
                      <div class="header-container-details-first">
                        <div class="profile-image">
                          <img
                            :src="caregiver.profile_picture"
                            alt="Profile Image"
                          />
                        </div>
                        <div class="header-container-details-first-div">
                          <div class="name-and-svg">
                            <h4>{{ caregiver.name }}</h4>
                            <div class="header-container-details-first-div-svg">
                              <img src="@/assets/01.svg" />
                              <img src="@/assets/02.svg" />
                              <img src="@/assets/03.svg" />
                            </div>
                          </div>
                          <div>{{ caregiver.location }}</div>
                          <!-- <div style="display: flex; align-items: center">
                            <span
                              v-for="star in 5"
                              :key="star"
                              class="star"
                              :class="{ filled: star <= caregiver.ratings }"
                              >★</span
                            >
                            <span>(4)</span>
                          </div> -->
                          <!-- <div>{{ caregiver.years_of_experience }}</div> -->
                          <div
                            style="display: flex; justify-content: flex-start"
                          >
                            <p style="white-space: pre-line; font-size: 16px">
                              {{ caregiver.experience }}
                            </p>
                          </div>
                          <div class="costing">
                            <h4>N{{ caregiver.monthly_rate }}</h4>
                            <span>per month</span>
                          </div>
                        </div>
                      </div>
                      <div class="caregiver-cost">
                        <!-- <svg
                          width="31"
                          height="28"
                          viewBox="0 0 31 28"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M22.6076 0C19.9526 0 17.4044 1.23597 15.7412 3.1891C14.0779 1.23597 11.5297 0 8.87467 0C4.17494 0 0.4823 3.69264 0.4823 8.39237C0.4823 14.1602 5.67031 18.8599 13.5286 26.0011L15.7412 28L17.9537 25.9858C25.812 18.8599 31 14.1602 31 8.39237C31 3.69264 27.3074 0 22.6076 0ZM15.8937 23.7275L15.7412 23.8801L15.5886 23.7275C8.32535 17.151 3.53407 12.8022 3.53407 8.39237C3.53407 5.3406 5.8229 3.05177 8.87467 3.05177C11.2245 3.05177 13.5134 4.5624 14.3221 6.65286H17.1755C17.9689 4.5624 20.2578 3.05177 22.6076 3.05177C25.6594 3.05177 27.9482 5.3406 27.9482 8.39237C27.9482 12.8022 23.157 17.151 15.8937 23.7275Z"
                            fill="#979797"
                          />
                        </svg> -->
                        <BaseButton
                          :label="`Contact ${caregiver.name}`"
                          backgroundColor="var(--s-pink-normal)"
                          textColor="var(--state-white)"
                          height="60px"
                          width="250px"
                          @click="handleClick(caregiver.profileId)"
                        />
                        <BaseButton
                          :label="`Hire ${caregiver.name}`"
                          backgroundColor="var(--s-pink-normal)"
                          textColor="var(--state-white)"
                          height="60px"
                          width="250px"
                          @click="handleHire(caregiver.id)"
                        />
                      </div>
                    </div>

                    <div class="lower-div">
                      <!-- <div class="caregiver-details">
                      {{ caregiver.details }}
                    </div> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="content-container">
            <div class="content">
              <div class="content-one">
                <div class="content-one-content">
                  <h4>About {{ caregiver.name }}</h4>
                  <p>
                    {{ caregiver.details }}
                    <!-- <span class="read-more">Read more</span> -->
                  </p>
                </div>
                <div class="content-one-content">
                  <h4>Categories</h4>
                  <p class="sub-header">
                    {{ caregiver.category }}
                  </p>
                  <!-- <div
                    class="sub-category"
                    v-for="sub in caregiver.sub_categories"
                    :key="sub"
                  >
                    <span>
                      <svg
                        width="12"
                        height="10"
                        viewBox="0 0 12 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M4 9.4L0 5.4L1.4 4L4 6.6L10.6 0L12 1.4L4 9.4Z"
                          fill="black"
                        />
                      </svg> </span
                    ><span>{{ sub }}</span>
                  </div> -->
                  <div class="sub-category">
                    <span>
                      <svg
                        width="12"
                        height="10"
                        viewBox="0 0 12 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M4 9.4L0 5.4L1.4 4L4 6.6L10.6 0L12 1.4L4 9.4Z"
                          fill="black"
                        />
                      </svg> </span
                    ><span>{{ caregiver.sub_categories }}</span>
                  </div>
                </div>
                <div class="content-one-content">
                  <h4>Reviews</h4>

                  <div v-if="caregiver.reviewers.length === 0">
                    No reviewers yet ...
                  </div>
                  <div v-else>
                    <div class="reviews">
                      <div>
                        <h3>5.0</h3>
                      </div>
                      <div class="reviews_stars">
                        <div>
                          <span
                            v-for="star in 5"
                            :key="star"
                            class="star"
                            :class="{ filled: star <= caregiver.ratings }"
                            >★</span
                          >
                        </div>

                        <div><p>Based on 5 reviews</p></div>
                      </div>
                    </div>

                    <div
                      class="reviewers"
                      v-for="reviewer in caregiver.reviewers"
                      :key="reviewer"
                    >
                      <div class="reviewer-first">
                        <Avatar :initials="reviewer.initials" />
                        <p>{{ reviewer.name }}</p>
                      </div>
                      <div style="padding-top: 16px">
                        <div>
                          <span
                            v-for="star in 5"
                            :key="star"
                            class="star"
                            :class="{ filled: star <= caregiver.ratings }"
                            >★</span
                          >
                        </div>
                        <p class="small">
                          Reviewed on {{ reviewer.date_reviewed }}
                        </p>
                        <p style="margin-top: 16px">{{ reviewer.details }}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="content-one-content-last">
                  <h4>Safety</h4>

                  <div style="display: flex; align-items: center; gap: 8px">
                    <svg
                      width="21"
                      height="25"
                      viewBox="0 0 21 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.85328 24.2167C8.55763 23.7608 7.30397 23.2089 6.13428 22.4711C3.42901 20.7736 1.65949 18.3802 0.765736 15.3271C0.393837 14.0674 0.207887 12.7718 0.0999164 11.4641C-0.050043 9.77257 0.00994074 8.07503 0.0219375 6.38349C0.0219375 5.83764 0.0759229 5.29179 0.363845 4.79992C0.843715 3.97214 1.56352 3.52826 2.51126 3.46228C3.69294 3.37231 4.84463 3.18036 5.95433 2.73648C7.09402 2.2866 8.10175 1.63278 8.98951 0.793004C9.31942 0.47509 9.69132 0.235155 10.1772 0.223158C10.6751 0.211161 11.0709 0.415106 11.4129 0.745017C12.2106 1.50081 13.1044 2.12464 14.1121 2.57452C15.1678 3.04839 16.2775 3.31232 17.4292 3.41429C18.0471 3.46828 18.6589 3.50427 19.1987 3.85817C19.9485 4.35004 20.3504 5.03985 20.4044 5.93961C20.4824 7.28924 20.4704 8.63888 20.4344 9.98851C20.3804 12.046 20.1405 14.0794 19.4567 16.0349C18.413 19.0221 16.4755 21.2655 13.7342 22.825C12.7325 23.3949 11.6768 23.8388 10.5971 24.2227H9.84728L9.85328 24.2167ZM18.9828 8.9388C18.9828 8.71086 18.9828 8.48892 18.9828 8.26098C18.9828 7.55317 18.9828 6.83937 18.9648 6.13156C18.9528 5.42975 18.5809 5.02186 17.8791 4.92588C17.3752 4.8599 16.8714 4.83591 16.3675 4.75193C15.1858 4.55998 14.0641 4.20608 12.9964 3.66023C12.0487 3.18036 11.2029 2.55653 10.4351 1.83072C10.2732 1.68076 10.1532 1.68676 9.99724 1.83072C9.50537 2.2866 8.98951 2.71848 8.42566 3.08438C6.71613 4.20008 4.83263 4.75193 2.81118 4.8839C1.92942 4.94388 1.47954 5.34577 1.47954 6.12556C1.47954 7.70913 1.40756 9.2927 1.50354 10.8763C1.59351 12.3279 1.79146 13.7615 2.22334 15.1531C2.82318 17.0966 3.8609 18.7701 5.39648 20.1138C6.74612 21.2955 8.2997 22.0992 9.97324 22.7051C10.1532 22.771 10.3211 22.7531 10.4951 22.6931C11.2869 22.4051 12.0607 22.0692 12.7985 21.6614C14.52 20.7136 15.9536 19.4659 17.0033 17.7864C17.8011 16.5028 18.287 15.1051 18.5809 13.6295C18.8928 12.0759 18.9648 10.5044 18.9648 8.9328L18.9828 8.9388Z"
                        fill="#126B4C"
                      />
                      <path
                        d="M10.2075 18.2294C6.90843 18.2174 4.20316 15.4881 4.22116 12.201C4.23915 8.90788 6.95642 6.21461 10.2435 6.2266C13.5426 6.2386 16.2539 8.96786 16.2299 12.261C16.2119 15.5541 13.4946 18.2473 10.2075 18.2354V18.2294ZM10.2255 16.7597C12.7389 16.7597 14.7603 14.7323 14.7543 12.219C14.7543 9.71166 12.7329 7.6962 10.2315 7.6962C7.71821 7.6962 5.69076 9.71166 5.69076 12.225C5.69076 14.7383 7.71221 16.7597 10.2255 16.7597Z"
                        fill="#126B4C"
                      />
                      <path
                        d="M9.16881 14.8655C8.95287 14.8655 8.77292 14.7816 8.62296 14.6316C8.22106 14.2297 7.81318 13.8338 7.41728 13.4259C7.11137 13.108 7.11137 12.6881 7.39329 12.3942C7.68121 12.0943 8.11309 12.0883 8.443 12.3942C8.64095 12.5742 8.8269 12.7601 9.00685 12.9581C9.13282 13.096 9.2108 13.084 9.34276 12.9581C10.1825 12.1123 11.0223 11.2665 11.8681 10.4268C12.216 10.0848 12.6599 10.0728 12.9658 10.3848C13.1937 10.6247 13.2357 10.9666 13.0678 11.2485C13.0198 11.3265 12.9598 11.4045 12.8938 11.4705C11.8561 12.5142 10.8124 13.5579 9.76865 14.5956C9.60669 14.7576 9.42074 14.8835 9.17481 14.8775L9.16881 14.8655Z"
                        fill="#126B4C"
                      />
                    </svg>

                    <span>Verified caregiver</span>
                  </div>
                </div>
              </div>
              <div class="content-two">
                <div class="content-two-sub">
                  <h4>Availability</h4>
                  <p class="small-text">{{ caregiver.availability }}</p>
                  <!-- <p class="small-text">Mornings, Afternoon and Evening</p> -->
                </div>
                <div class="content-two-sub">
                  <h4>Rate</h4>
                  <p class="small-text">
                    N{{ caregiver.monthly_rate }} / month
                  </p>
                </div>
                <div class="content-two-sub">
                  <h4>Phone</h4>
                  <p class="small-text">
                    {{ caregiver.phone }}
                  </p>
                </div>
                <div class="content-two-sub">
                  <h4>Preferences</h4>
                  <p class="small-text">{{ caregiver.work_preference }}</p>
                </div>
                <div class="content-two-sub">
                  <h4>Education</h4>
                  <p class="small-text">{{ caregiver.institution }}</p>
                </div>
                <div class="content-two-sub-last">
                  <h4>Language</h4>

                  <div
                    v-for="lang in caregiver.languages"
                    :key="lang.id"
                    class="sub-category"
                  >
                    <span>
                      <svg
                        width="12"
                        height="10"
                        viewBox="0 0 12 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M4 9.4L0 5.4L1.4 4L4 6.6L10.6 0L12 1.4L4 9.4Z"
                          fill="black"
                        />
                      </svg> </span
                    ><span>{{ lang.name }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <GenericModal
      :isVisible="isMainModalVisible"
      :title="modalTitle"
      @close="hideModal"
    >
      <template v-if="currentModal == 'contact'">
        <div class="contact-message-div">
          <label for="">Contact {{ caregiver.name }} </label>
          <textarea v-model="contactmessage"> </textarea>
          <div class="button-div">
            <BaseButton
              :label="
                sendingMessage ? `Contacting ${caregiver.name}` : 'Send Message'
              "
              backgroundColor="var(--s-pink-normal)"
              textColor="var(--state-white)"
              height="60px"
              width="250px"
              @click="handleContactMessage"
            />
          </div>
        </div>
      </template>
      <template v-if="currentModal == 'hire'">
        <div class="availability-container">
          <div class="modal-content">
            <!-- <p>You would have to pay screening fee to hire this caregiver!</p> -->
            <p style="text-align: center; font-size: 20px">
              It’s imperative to conduct comprehensive screening of caregivers
              before hiring them.
              <br />
              Please choose a screening option
            </p>
            <div
              class="delete-div reduceFont"
              style="
                margin-bottom: 15px;
                gap: 10px;
                display: flex;
                flex-direction: column;
              "
            >
              <BaseButton
                label="I want Succour to conduct the screening"
                backgroundColor="var(--s-pink-normal)"
                textColor="var(--state-white)"
                height="60px"
                :fontSize="isLargeScreen ? '20px' : '10px'"
                :width="isLargeScreen ? '320px' : '200px'"
                @click="handleHireProceed"
              />
              <BaseButton
                label="I will conduct the screening myself"
                borderColor="var(--s-pink-normal)"
                backgroundColor="transparent"
                textColor="var(--s-pink-normal)"
                height="60px"
                :fontSize="isLargeScreen ? '20px' : '10px'"
                :width="isLargeScreen ? '320px' : '200px'"
                @click="handleScreeningByCareseeker"
              />
            </div>
          </div>
        </div>
      </template>
      <template v-if="currentModal == 'selfScreening'">
        <div class="availability-container">
          <div class="modal-content">
            <!-- <p>You would have to pay screening fee to hire this caregiver!</p> -->
            <h4 style="text-align: center; margin-bottom: 10px;white-space: wrap">
              Disclaimer: Private Screening Responsibility
            </h4>
            <p class="small-p">
              By opting to conduct screening privately, you assume full
              responsibility for verifying the caregiver’s background. This
              includes, but is not limited to, the following checks: <br />
              - Comprehensive
              <br />
              - Medical Tests <br />
              - Criminal Background Check <br />
              - Guarantor Verification <br />
              Succour Support Systems Limited is not liable for any screening
              outcomes conducted outside of its services. For safety and quality
              assurance, we recommend using trusted professionals for all
              verifications. Continue with Private Screening?
              <br />
              Please choose a screening option
            </p>
            <div
              class="delete-div reduceFont"
              style="
                margin-bottom: 15px;
                gap: 10px;
                display: flex;
                flex-direction: column;
              "
            >
              <BaseButton
                label="Yes, I understand and accept"
                backgroundColor="var(--s-pink-normal)"
                textColor="var(--state-white)"
                height="60px"
                :fontSize="isLargeScreen ? '20px' : '10px'"
                :width="isLargeScreen ? '320px' : '200px'"
                @click="handleScreeningByCareseekerProceed"
              />
              <BaseButton
                label="Cancel"
                borderColor="var(--s-pink-normal)"
                backgroundColor="transparent"
                textColor="var(--s-pink-normal)"
                height="60px"
                :fontSize="isLargeScreen ? '20px' : '10px'"
                :width="isLargeScreen ? '320px' : '200px'"
                @click="handleHire"
              />
            </div>
            <div>
              <p style="text-align: center; font-size: 10px">
                Succour’s screening covers: Comprehensive Medical Tests,
                Criminal Background Check and Guarantor Verification.
              </p>
            </div>
          </div>
        </div>
      </template>
    </GenericModal>
  </DashboardLayout>
</template>

<script>
import { ref, onMounted, watch } from "vue";
import BaseButton from "@/components/BaseButton.vue";
import "@vueform/multiselect/themes/default.css";
import statesData from "@/data/states.js";
import Avatar from "@/components/AvatarComponent.vue";
import DashboardLayout from "@/views/Dashboard/Caregiver/IndexPage.vue";
import { useRoute, useRouter } from "vue-router";
import Request from "@/utils/apiclient.js";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import { formatString } from "@/utils/formatLgaorStates";
import GenericModal from "@/components/GenericModal.vue";
import ToastManager from "@/components/ToastAlert/ToastManager.vue";
import { formatErrorMessages } from "@/utils/errorHandler";
export default {
  name: "CareSeekerHome",
  components: {
    BaseButton,
    Avatar,
    DashboardLayout,
    LoadingSpinner,
    GenericModal,
    ToastManager,
  },
  setup() {
    const router = useRouter();
    const toastManager = ref(null);
    const showToast = (msg, statusValue) => {
      toastManager.value.addToast(msg, statusValue);
    };
    const userProfile = JSON.parse(localStorage.getItem("_userProfile"));
    const isMainModalVisible = ref(false);
    const currentModal = ref("");
    const modalTitle = ref("");

    const showModal = (modalType) => {
      currentModal.value = modalType;
      isMainModalVisible.value = true;
    };
    //    const getModalTitle = (modalType) => {
    //   switch (modalType) {
    //     case "contact":
    //       return "Update your account information";
    //     case "hire":
    //       return "Update your password";
    //     default:
    //       return "";
    //   }
    // };

    const hideModal = () => {
      isMainModalVisible.value = false;
    };
    const contactmessage = ref("");
    // const careCategory = ref("senior care");
    // const setCategory = (cate) => {
    //   careCategory.value = cate;
    // };
    const isLoading = ref(true);
    // const router = useRouter();
    const route = useRoute();

    const payRate = ref({ pay_min: 10000, pay_max: 100000 }); // Example initial data

    const handleRateUpdate = ({ minRate, maxRate }) => {
      payRate.value.pay_min = minRate;
      payRate.value.pay_max = maxRate;
    };
    const caregiverId = ref(null);
    const handleClick = (id) => {
      console.log("id here", id);
      caregiverId.value = id;
      showModal("contact");

      // router.push("/careseeker/messages");
    };
    const caregiverIdForPayment = ref("");
    const handleHire = (id) => {
      showModal("hire");
      console.log("id hire here", id);
      caregiverIdForPayment.value = id;

      // caregiverId.value = id;

      // router.push("/careseeker/messages");
    };
    const handleHireProceed = () => {
      console.log("id hire payment here", caregiverIdForPayment.value);
      router.push(`/careseeker/payment/${caregiverIdForPayment.value}`);
      // caregiverId.value = id;

      // router.push("/careseeker/messages");
    };
    const handleScreeningByCareseeker = () => {
      showModal("selfScreening");
    };
    const handleScreeningByCareseekerProceed = () => {
      showModal("selfScreening");
      isLoading.value = true;

      let payload = {
        care_giver: caregiverIdForPayment.value,
      };
      let request = new Request(`/hire/direct/`);

      const rawData = JSON.stringify(payload);
      request.post(rawData, (res, err) => {
        isLoading.value = false;
        if (err) {
          hideModal();
          console.error("Error save acc:", err);
          // showToast("Failed to hire caregiver", "error");
          const errorMessages = formatErrorMessages(err);
          console.log("error", errorMessages);
          errorMessages.forEach((message) => showToast(message, "error"));
        } else {
          hideModal();
          console.log("here is for hire", res);
          showToast("Care giver hired successfully", "success");

          setTimeout(() => {
            router.push("/careseeker/hired");
          }, 3000);
          // if (res.status == "success") {

          // }
          // showToast("Payment Successful", "success");
        }
      });
    };
    const isActive = ref("child care");
    const determiningValue = ref("academic care");
    const theOptions = ref([]);
    const setActiveTab = (act) => {
      isActive.value = act;
      determiningValue.value = act;
    };
    const stateOptions = statesData.states.map((state) => ({
      value: state.state,
      text: state.state,
    }));

    const cityOptions = ref([]);

    const updateCityOptions = (state) => {
      const selectedStateData = statesData.states.find(
        (s) => s.state === state
      );
      cityOptions.value = selectedStateData
        ? selectedStateData.lgas.map((lga) => ({ value: lga, text: lga }))
        : [];
    };
    const caregiver = ref({
      id: 1,
      name: "Taiwo",
      location: "Lagos, NG",
      experience: "Errands, housekeeping and meal prep",
      years_of_experience: "3 years",
      details:
        "I'm a compassionate and dedicated senior caregiver with [number] years of experience. My passion lies in helping seniors maintain their independence and live life to the fullest. I believe in creating a warm and supportive environment where dignity and respect are paramount. ",
      monthly_rate: "N3000",
      ratings: 5,
      profile_picture: "woman.png",
      category: "Senior care",
      sub_categories: ["In home care", "Companionship", "Transportation"],
      availability: "",
      work_preference: "",
      username: "",
      languages: [],
      review: [],
      profileId: 0,
      reviewers: [
        // {
        //   name: "Lisa Raymonds",
        //   ratings: 5,
        //   date_reviewed: "3/22/2023",
        //   details:
        //     "She's been an absolute godsend for my elderly father.  He can be quite stubborn at times, but Mary has a way of putting him at ease and making him smile.  She's incredibly patient, kind, and always goes the extra mile.",
        //   initials: "AV",
        // },
        // {
        //   name: "Nera Loyaymonds",
        //   ratings: 5,
        //   date_reviewed: "3/22/2023",
        //   details:
        //     "She's been an absolute godsend for my elderly father.  He can be quite stubborn at times, but Mary has a way of putting him at ease and making him smile.  She's incredibly patient, kind, and always goes the extra mile.",
        //   initials: "AV",
        // },
      ],
    });

    const updateTheOptions = () => {
      if (determiningValue.value === "academic care") {
        theOptions.value = [
          { text: "Mathematics", value: "mathematics" },
          { text: "English", value: "english" },
          // More options for academic care
        ];
      } else if (determiningValue.value === "child care") {
        theOptions.value = [
          { text: "Live in", value: "live_in" },
          { text: "Live out", value: "live_out" },
          { text: "Either", value: "either" },
          // More options for child care
        ];
      } else if (determiningValue.value === "senior care") {
        theOptions.value = [
          { text: "Live in", value: "live_in" },
          { text: "Live out", value: "live_out" },
          { text: "I am not sure", value: "not sure" },
          // More options for child care
        ];
      } else if (determiningValue.value === "house care") {
        theOptions.value = [
          { text: "Live in", value: "live_in" },
          { text: "Live out", value: "live_out" },
          { text: "I am not sure", value: "not sure" },
          // More options for child care
        ];
      }
    };

    onMounted(() => {
      updateTheOptions(); // Assuming this is defined elsewhere

      const careGiverId = route.params.id;

      const fetchCaregivers = () => {
        const request = new Request(`/care-giver/${careGiverId}/`);
        request.get((res, err) => {
          isLoading.value = false;
          if (err) {
            console.error("Error:", err);
          } else {
            console.log("here caregiver", res);
            caregiver.value.id = res.id;
            caregiver.value.name =
              res.user.first_name + " " + res.user.last_name;
            caregiver.value.location = formatString(res.lga) + ", " + res.state;
            caregiver.value.experience = res.sub_category
              .map((sub) => sub.name)
              .join(", ");
            caregiver.value.monthly_rate = res.monthly_rate;
            caregiver.value.details = res.bio;
            caregiver.value.profile_picture = res.user.profile_picture;
            caregiver.value.category = res.category
              .map((cat) => cat.name)
              .join(", ");
            caregiver.value.sub_categories = res.sub_category
              .map((sub) => sub.name)
              .join(", ");
            caregiver.value.availability =
              res.availabilities[0].days.join(", ");
            caregiver.value.institution = res.institution;
            caregiver.value.work_preference = res.work_preference;
            caregiver.value.languages = res.language;
            caregiver.value.username = res.user.username;
            caregiver.value.profileId = res.user.id;
            caregiver.value.phone = res.user.phone;
          }
        });
      };

      fetchCaregivers(); // This needs to be inside the onMounted hook
    });

    watch(determiningValue, () => {
      updateTheOptions();
    });
    const sendingMessage = ref(false);
    const handleContactMessage = () => {
      if (sendingMessage.value) return;
      sendingMessage.value = true;
      const request = new Request(`/message/`);
      const contactMessagePayload = {
        message: contactmessage.value,
        recipient: caregiverId.value,
        group_name: userProfile.username + "_" + caregiver.value.username,
      };
      const rawData = JSON.stringify(contactMessagePayload);
      request.post(rawData, (res, err) => {
        isLoading.value = false;
        sendingMessage.value = false;
        if (err) {
          console.error("Error save acc:", err);
          if (err.status == false) {
            return showToast(
              `You have already contacted this care giver`,
              "error"
            );
          }
          showToast(`Error contacting caregiver`, "error");
        } else {
          console.log("here is response save account", res);
          if (res.message && res.group_name) {
            showToast(
              `You have contacted ${caregiver.value.name} successfully`,
              "success"
            );
            hideModal();
          }
        }
      });
    };
    return {
      isActive,
      setActiveTab,
      caregiver,
      updateTheOptions,
      theOptions,
      stateOptions,
      updateCityOptions,
      handleRateUpdate,
      payRate,
      handleClick,
      isLoading,
      contactmessage,
      showModal,
      hideModal,
      modalTitle,
      isMainModalVisible,
      handleContactMessage,
      toastManager,
      sendingMessage,
      handleHire,
      handleHireProceed,
      currentModal,
      handleScreeningByCareseeker,
      handleScreeningByCareseekerProceed,
    };
  },
};
</script>

<style scoped>
textarea {
  display: flex;
  flex-direction: column;
  height: 197px;
  width: 100%;
  padding: 16px;
  background-color: transparent;
  gap: 10px;
  border-radius: 12px;
  box-sizing: border-box;
  border: 1px solid #979797;
  margin-top: 16px;
}
.contact-message-div {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.contact-message-div textarea {
  height: 300px;
}
.contact-message-div .button-div {
  display: flex;
  justify-content: flex-end;
}
.content-container {
  display: flex;
  justify-content: center;
}
.careseeker-container {
  /* Add necessary styles here */
  width: 100%;
  display: flex;
  flex-direction: column;
}

.notice {
  text-align: center;
  padding: 10px;
  background-color: var(--s-pink-light);
  display: flex;
  justify-content: center;
  gap: 20px;
  align-items: center;
  margin-top: 32px;
  margin-bottom: 32px;
}
.content {
  display: flex;
  gap: 97px;
  /* padding: 26px 90px 100px 153px; */
  justify-content: center;
  width: 1184px;
}
.notice p {
  font-size: 20px;
  font-weight: 400;
  line-height: 34px;
  letter-spacing: 0.5px;
  text-align: left;
}
.header-container-details-first-div {
  display: flex;
  gap: 8px;
  flex-direction: column;
  justify-content: flex-start;
}
.header-container-details-first-div div {
  display: flex;

  justify-content: flex-start;
}
.header-container-details-first-div-svg {
  gap: 16px;
  display: flex;
  align-items: center;
}
.header-container-details {
  display: flex;
  justify-content: space-between;
  width: 1184px;
}
.header-container-details-first {
  display: flex;
  gap: 18px;
}
.profile-image-container {
  display: flex;
  justify-content: center;
}
.profile-image {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
}

.profile-image img {
  width: 200px;
  height: 200px;
  border-radius: 12px;
}
.modal-content {
  max-width: 400px;
}
h4 {
  margin: 0px;

  font-size: 20px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: 0.5px;
}
.name-and-svg {
  display: flex;
  align-self: start;
  gap: 24px;
}
.lower-div {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 16px;
}

.caregivers-list-first {
  width: 100%;
}
.caregivers-list {
  width: 100%;
}
.caregivers-list-individual {
  width: 100%;
}
.caregiver-cost {
  font-size: 24px;
  font-weight: 700;
  line-height: 20.4px;
  letter-spacing: 0.5px;
  text-align: right;
  display: flex;
  /* flex-direction: column; */
  align-items: flex-end;
  justify-content: flex-end;
  gap: 15px;
}
.caregiver-cost span {
  font-size: 14px;
  font-weight: 400;
  line-height: 23.8px;
  letter-spacing: 0.5px;
  text-align: left;
}
.header-container {
  /* padding-bottom: 40px; */
  /* padding-left: 160px;
  padding-right: 160px; */
  margin-bottom: 38px;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.star {
  font-size: 20px;
  margin-right: 6px;
}

.star.filled {
  color: #ffcc00;
}

.star:not(.filled) {
  color: #ddd;
}
.actual-filter-container {
  background-color: #ececec4d;
  border-radius: 12px;
  padding: 10px 16px 10px 16px;
}

.custom-multiselect {
  position: relative;
  width: 100%;
}
.multiselect-option.is-selected {
  background: var(--s-green-normal-active);
  color: #000;
}
.custom-multiselect .multiselect {
  z-index: 11;
  width: 100%;
  padding: 10px;
  height: 60px;
  margin-bottom: 24px;
  font-size: 16px;
  border: 1px solid #979797;
  border-radius: 12px;
  background-color: transparent; /* White background color */
}

.custom-multiselect .multiselect__content {
  background-color: transparent; /* White background for options */
}
.multiselect {
  background: transparent;
}
.custom-multiselect-selected .multiselect {
  border: 1px solid var(--s-green-normal-active); /* Change to desired color when a value is selected */
}
.state-select {
  position: relative;
  z-index: 2;
}

.city-select {
  position: relative;
  z-index: 1;
}

.state-select .multiselect--active .multiselect__content-wrapper {
  z-index: 3;
}

.city-select .multiselect--active .multiselect__content-wrapper {
  z-index: 2;
}
.state-city-div {
  display: flex;
  gap: 16px;
}
h5 {
  font-size: 20px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: 0.5px;
  text-align: left;
  margin: 0px 0px 10px 0px;
}
.small-text {
  font-size: 16px;
  font-weight: 700;
  line-height: 27.2px;
  letter-spacing: 0.5px;
  text-align: left;
  margin-bottom: 10px;
}
.filter-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.button-div {
  display: flex;
  justify-content: space-between;
}
.costing {
  display: flex;
  flex-direction: column;
}
.costing h4 {
  margin: 0px;
  text-align: left;
}
.costing span {
  font-size: 14px;
  font-weight: 400;
  line-height: 23.8px;
  letter-spacing: 0.5px;
  text-align: left;
}
.content-one {
  display: flex;
  flex-direction: column;
  width: 691.5px;
}
.content-one-content {
  display: flex;
  border-bottom: 1px solid black;
  padding-bottom: 24px;
  margin-bottom: 24px;
  flex-direction: column;
}
.content-one-content-last {
  display: flex;
  gap: 24px;
  padding-bottom: 24px;
  margin-bottom: 24px;
  flex-direction: column;
}
.content-two {
  display: flex;
  width: 372px;
  flex-direction: column;
}
.read-more {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 23.8px;
  letter-spacing: 0.5px;
  color: var(--state-info);
}
.sub-category {
  display: flex;
  align-items: center;
  gap: 8px;
  padding-top: 8px;
}
.sub-category span {
  font-size: 16px;
  font-weight: 400;
  line-height: 27.2px;
  letter-spacing: 0.5px;
}
.sub-header {
  font-size: 14px;
  font-weight: 700;
  line-height: 23.8px;
  letter-spacing: 0.5px;
  text-align: left;
  margin-bottom: 8px;
  margin-top: 16px;
}
.reviews {
  display: flex;
  gap: 16px;
  margin-bottom: 16px;
}
.reviews h3 {
  font-size: 44px;
  font-weight: 700;
  line-height: 57.2px;
  letter-spacing: 0.5px;
  text-align: left;
  margin: 0px;
}
.reviews_stars {
  display: flex;
  flex-direction: column;
}
.reviewers {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
}
.reviewer-first {
  display: flex;
  align-items: center;
  gap: 16px;
}
.small {
  font-size: 12px;
  font-weight: 400;
  line-height: 20.4px;
  letter-spacing: 0.5px;
  text-align: left;
}

.content-two {
  display: flex;
  flex-direction: column;
}
.content-two-sub {
  display: flex;
  padding-bottom: 16px;
  margin-bottom: 16px;
  flex-direction: column;
  border-bottom: 1px solid #979797;
  gap: 8px;
}
.content-two-sub-last {
  display: flex;
  padding-bottom: 16px;
  margin-bottom: 16px;
  flex-direction: column;

  gap: 8px;
}
.small-text {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 8px;
  line-height: 27.2px;
  letter-spacing: 0.5px;
  text-align: justified;
}
.delete-div {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}
.availability-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  font-family: Arial, sans-serif;
}
.reduceFont > .base-button {
  font-size: 16px;
}

@media (max-width: 800px) {
  .header-container-details {
    width: 100%;
    max-width: 320px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .caregiver-cost {
    flex-direction: column;
    margin-top: 30px;
  }
  .profile-image img {
    width: 150px;
    height: 150px;
  }
  h4 {
    font-size: 16px;
    white-space: nowrap;
  }
  .name-and-svg {
    flex-direction: column;
  }
  .content {
    flex-direction: column;
    align-items: center;
  }
  .content-one {
    width: 100%;
    max-width: 320px;
  }
  .content-two {
    width: 100%;
    max-width: 320px;
  }
  .small-p {
    font-size: 12px;
  }
  .modal-content {
    max-width: 300px;
    padding-left: 20px;
    padding-right: 20px;
  }
    .header-container-details-first{
    flex-direction: column;
  }
}
</style>
