<template>
  <button
    :class="['base-button', buttonType, { 'has-icon': icon, 'is-disabled': isDisabled }]"
    :style="buttonStyles"
    @click="handleClick"
    :disabled="isDisabled"
  >
    <span v-if="icon" v-html="icon" class="iconclass"></span>
    <span>{{ label }}</span>
  
  </button>
</template>

<script setup>
import { computed, watch } from 'vue';
import { defineProps, defineEmits } from 'vue';

const props = defineProps({
  label: {
    type: String,
    required: true
  },
  fontSize: {
    type: String,
       default: '16px'
  },
  buttonType: {
    type: String,
    default: 'default'
  },
  icon: {
    type: String,
    default: ''
  },
  backgroundColor: {
    type: String,
    default: '#ECECEC'
  },
  disabledBackgroundColor: {
    type: String,
    default: ''
  },
  textColor: {
    type: String,
    default: ''
  },
  borderColor: {
    type: String,
    default: ''
  },
  handleClick: {
    type: Function,
    default: null
  },
  width: {
    type: String,
    default: ''
  },
  disabled: {
    type: Boolean,
    default: false
  },
  disabledColor: {
    type: String,
    default: '#ECECEC'
  },
  disabledTextColor: {
    type: String,
    default: '#979797'
  },
  height: {
     type: String,
    default: ''
  },
  disabledBorderColor: {
    type: String,
    default: ''
  },
  borderRadius: {
    type: String,
    default: ''
  }
 
});

const emit = defineEmits(['click']);

const isDisabled = computed(() => props.disabled);

const buttonStyles = computed(() => ({
  backgroundColor: isDisabled.value ? props.disabledBackgroundColor : props.backgroundColor,
  color: isDisabled.value ? props.disabledTextColor : props.textColor,
  border: props.borderColor ? `1px solid ${props.borderColor}` : "",
  width: props.width,
  height: props.height,
  cursor: isDisabled.value ? 'not-allowed' : 'pointer',
  opacity: isDisabled.value ? 0.6 : 1,
  fontSize: props.fontSize ? props.fontSize : "16px",
  borderRadius: props.borderRadius,
}));

const handleClick = (event) => {
  if (!isDisabled.value) {
    if (props.handleClick) {
      props.handleClick(event);
    } else {
      emit('click', event);
    }
  }
};

watch(() => props.disabled, (newVal) => {
  if (newVal) {
    // Add additional logic if needed when disabled state changes
  }
});
</script>

<style scoped>
.base-button {
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  padding: 10px 20px;
  font-size: 16px;
  border: 2px solid transparent;
  border-radius: 25px;
  cursor: pointer;
  box-sizing: border-box;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s, opacity 0.3s;
}

.base-button.default {
  background-color: var(--s-pink-normal);
  color: var(--state-white);
}
.has-icon{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px
}

.iconclass{
  height: 100%;
display: flex;
align-items: center;
}
.base-button:hover {
  opacity: 0.8;
}

/* Additional Button Types */
.base-button.secondary {
  background-color: var(--state-light-grey);
  color: var(--state-grey);
}

.base-button.outline {
  background-color: transparent;
  border-color: var(--s-green-normal);
  color: var(--s-green-normal);
}

.base-button.outline.secondary {
  border-color: var(--state-grey);
  color: var(--state-grey);
}

/* Disabled state */
.base-button.is-disabled {
  cursor: not-allowed;
  opacity: 0.6;
}
</style>
