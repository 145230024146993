<template>
  <div class="login-layout-container">
    <div class="main">
      <header>
        <!-- <nav class="navbar">
          <img @click="redirectToHome" class="main-logo" src="../assets/full_logo.svg" alt="Logo" />
          <div class="links">
            <router-link class="normal-links" to="/">Apply To Jobs</router-link>
            <router-link class="normal-links" to="/">Home</router-link>
            <router-link class="join-now" to="/onboarding">Join now</router-link>
          </div>
        </nav> -->
        <nav class="navbar">
          <img
            @click="redirectToHome"
            class="main-logo"
            src="@/assets/full_logo.svg"
            alt="Logo"
          />
          <div class="links">
            <router-link class="normal-links" to="/onboarding"
              >Apply To Care Jobs</router-link
            >
            <router-link class="normal-links" to="/login">Log In</router-link>
            <router-link class="join-now" to="/onboarding"
              >Get Care</router-link
            >
          </div>
        </nav>
      </header>

      <div class="main-one">
        <slot></slot>
      </div>
    </div>

    <!-- <footer>
      <div class="footer">
        <div class="footer-content-container">
          <div class="logoandsocialmedia">
            <img
              src="../assets/white_logo.svg"
              style="width: 167.32px; height: 60.05px; margin-bottom: 16px"
              alt="Logo"
            />
            <div style="margin-bottom: 61px">
              <p class="white-text">
               Succour helps families and individuals connect
               with trusted, verified caregivers
              </p>
            </div>
            <div class="social-media-links">
              <a
                href="https://www.linkedin.com"
                target="_blank"
                class="clickable-div"
              >
                <img src="../assets/linkedin.svg" />
              </a>
              <a
                href="https://www.facebook.com"
                target="_blank"
                class="clickable-div"
              >
                <img src="../assets/facebook.svg" />
              </a>
              <a
                href="https://www.instagram.com"
                target="_blank"
                class="clickable-div"
              >
                <img src="../assets/instagram.svg" />
              </a>
              <a href="https://www.x.com" target="_blank" class="clickable-div">
                <img src="../assets/x.svg" />
              </a>
            </div>
          </div>
          <div class="footer-section">
            <div>
              <p class="footer-header-text">About Succour</p>
            </div>
            <div>
              <router-link to="/">About us</router-link>
            </div>
            <div>
              <router-link to="/">Career</router-link>
            </div>
            <div>
              <router-link to="/">Terms of use</router-link>
            </div>
            <div>
              <router-link to="/">Privacy policy</router-link>
            </div>
          </div>
          <div class="footer-section">
            <div>
              <p class="footer-header-text">Get help</p>
            </div>
            <div>
              <router-link to="/">Safety</router-link>
            </div>
            <div>
              <router-link to="/">Articles and guidelines</router-link>
            </div>
            <div>
              <router-link to="/">Help center</router-link>
            </div>
          </div>
          <div class="footer-section">
            <div>
              <p class="footer-header-text">Services</p>
            </div>
            <div>
              <router-link to="/">Child care</router-link>
            </div>
            <div>
              <router-link to="/">Senior care</router-link>
            </div>
            <div>
              <router-link to="/">House care</router-link>
            </div>
            <div>
              <router-link to="/">Academic care</router-link>
            </div>
          </div>
        </div>
      </div>
    </footer> -->
    <footer>
      <div class="footer">
        <div class="footer-content-container">
          <div v-if="isLargeScreen" class="logoandsocialmedia">
            <img
              src="@/assets/white_logo.svg"
              style="width: 167.32px; height: 60.05px; margin-bottom: 16px"
              alt="Logo"
            />
            <div style="margin-bottom: 61px">
              <p class="white-text">
                Succour helps families and individuals connect with verified
                caregivers faster
              </p>
            </div>
            <div class="social-media-links">
              <a
                href="https://www.linkedin.com/company/succourcare/?viewAsMember=true"
                target="_blank"
                class="clickable-div"
              >
                <img src="@/assets/linkedin.svg" />
              </a>
              <a
                href="https://www.facebook.com/succour.care/"
                target="_blank"
                class="clickable-div"
              >
                <img src="@/assets/facebook.svg" />
              </a>
              <a
                href="https://www.instagram.com/succour.care"
                target="_blank"
                class="clickable-div"
              >
                <img src="@/assets/instagram.svg" />
              </a>
              <a
                href="https://x.com/succourcare"
                target="_blank"
                class="clickable-div"
              >
                <img src="@/assets/x.svg" />
              </a>
            </div>
          </div>
          <div v-if="!isLargeScreen" class="social-media-links">
            <a
              href="https://www.linkedin.com/company/succourcare/?viewAsMember=true"
              target="_blank"
              class="clickable-div"
            >
              <img src="@/assets/linkedin.svg" />
            </a>
            <a
              href="https://www.facebook.com/succour.care/"
              target="_blank"
              class="clickable-div"
            >
              <img src="@/assets/facebook.svg" />
            </a>
            <a
              href="https://www.instagram.com/succour.care"
              target="_blank"
              class="clickable-div"
            >
              <img src="@/assets/instagram.svg" />
            </a>
            <a
              href="https://x.com/succourcare"
              target="_blank"
              class="clickable-div"
            >
              <img src="@/assets/x.svg" />
            </a>
          </div>
          <div
            class="footer-section"
            v-for="(section, index) in footerSections"
            :key="index"
          >
            <div class="footer-header" @click="toggleSection(index)">
              <p class="footer-header-text">{{ section.title }}</p>
              <img
                v-if="!isLargeScreen"
                src="@/assets/dropdown_icon_white.svg"
                alt=""
                :class="{ open: isOpen(index) }"
              />
            </div>
            <div v-if="isLargeScreen || isOpen(index)">
              <router-link
                style="margin-bottom: 8px"
                v-for="(link, i) in section.links"
                :key="i"
                :to="link.url"
                >{{ link.text }}</router-link
              >
            </div>
          </div>
        </div>
        <div v-if="!isLargeScreen" class="logoandsocialmedia">
          <img
            src="@/assets/white_logo.svg"
            style="width: 167.32px; height: 60.05px; margin-bottom: 16px"
            alt="Logo"
          />
          <!-- <div style="margin-bottom: 61px">
              <p class="white-text">
                Succour helps families and individuals connect with trusted,
                verified caregivers
              </p>
            </div> -->
        </div>
        <div class="ft">
          Copyright © {{ currentYear }} Succour Company S.L. All rights
          reserved.
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import { ref, computed } from "vue";
import { useRouter } from "vue-router";
export default {
  name: "LoginLayout",
  setup() {
    const router = useRouter();
    const redirectToHome = () => {
      router.push("/");
    };
    const openSection = ref(null);

    const footerSections = [
      {
        title: "About",
        links: [
          { text: "Company", url: "/about-us" },
          { text: "FAQ", url: "/faq" },
          { text: "Terms of use", url: "/terms-of-use" },
          { text: "Privacy policy", url: "/privacy-policy" },
        ],
      },
      // {
      //   title: "Get help",
      //   links: [
      //     { text: "Safety", url: "/" },
      //     { text: "Articles and guidelines", url: "/" },
      //     { text: "Help center", url: "/" },
      //   ],
      // },
      {
        title: "Services",
        links: [
          { text: "Child care", url: "/onboarding" },
          { text: "Elderly care", url: "/onboarding" },
          { text: "Housekeeper", url: "/onboarding" },
          { text: "Tutor", url: "/onboarding" },
        ],
      },
    ];

    const toggleSection = (index) => {
      openSection.value = openSection.value === index ? null : index;
    };

    const isOpen = (index) => openSection.value === index;
    const currentYear = computed(() => new Date().getFullYear());
    const isLargeScreen = ref(window.innerWidth > 1200);

    const handleResize = () => {
      isLargeScreen.value = window.innerWidth > 1200;
      console.log("inner", window.innerWidth);
    };

    return {
      redirectToHome,
      footerSections,
      toggleSection,
      isOpen,
      currentYear,
      isLargeScreen,
      handleResize,
    };
  },
};
</script>


<style scoped>



.login-layout-container {
  background-color: #fffbfc;
  min-height: 100vh;
}
.main {
  background-image: url("../assets/Layer2.png"); /* Replace with the actual path to your image */
  /* background-size: cover;  */
  background-position: center; /* Center the image */
  background-repeat: no-repeat;
  /* margin-bottom: 80px; */
  background-color: #fffbfc;
  height: 100%;
  width: 100%;
}
.navbar {
  display: flex;
  padding: 32px 128px;
  justify-content: space-between;
}
header {
  box-shadow: 0px 4px 1px 0px #0000001a;
}
.main-logo {
  width: 168px;
  height: 59px;
  cursor: pointer;
}
.links {
  display: flex;
  gap: 30px;
}

.normal-links {
  color: var(--s-green-normal-active);
  text-decoration: none;
  font-size: var(--font-body-normal-regular-size);
  line-height: var(--font-body-normal-regular-line-height);
  display: flex;
  align-items: center;
  transition: background-color 0.3s ease;
}

.links router-link:hover {
  background-color: #007bff;
  color: #fff;
}

.links router-link-active {
  font-weight: bold;
}
.join-now {
  background-color: var(--s-pink-normal);
  width: 150px;
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--state-white);
  text-decoration: none;
  font-size: var(--font-body-normal-regular-size);
  line-height: var(--font-body-normal-regular-line-height);
}
header {
  background-color: #fff8f9;
}
.main-one {
  /* padding: 0px 48px 0px 48px; */
  padding: 0px;
  display: flex;
  /* align-items: flex-end; */
  flex-direction: column;
  justify-content: center;
  /* background-color: #fff8f9; */
}

/* .find-care-container {
  background-color: #fff8f9;
  padding-top: 31px;
  width: 100%;
} */
.find-care-container {
  background-color: var(--state-white);
  display: flex;
  justify-content: center;
  margin-top: 31px;
  padding-bottom: 51px;
  /* padding-top: 31px; */
  width: 100%;
}

.header-text {
  color: var(--s-green-darker);
  font-size: 54.1px;
  font-weight: 700;
  line-height: 70.32px;
  letter-spacing: 0.9634920597076416px;
}

.find-job-care-provider {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: var(--s-pink-normal);
  padding-top: 108.5px;
  padding-bottom: 108.5px;
  gap: 48px;
}
.find-job-care-provider {
  background-image: url("../assets/Layer1.png"); /* Replace with the actual path to your image */
  background-size: cover; /* Ensure the image covers the entire background */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Prevent the image from repeating */
  color: var(--state-white);
  font-size: 36px;
  font-weight: var(--font-weight-bold);
  line-height: 52px;
  letter-spacing: 0.5px;
  text-align: center;
}
.main-three {
  background-image: url("../assets/rectanglebackground.png"); /* Replace with the actual path to your image */
  background-size: cover; /* Ensure the image covers the entire background */
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Prevent the image from repeating */
  display: flex;
  justify-content: flex-end;
  padding-top: 49px;
  padding-bottom: 49px;
  padding-right: 91px;
}
.get-started-card {
  display: flex;
  flex-direction: column;
  background-color: var(--state-white);
  border-radius: 12px;
  padding: 16px 0px 16px 16px;
  gap: 8px;
}
.get-started-card-image-div {
  display: flex;
  justify-content: flex-start;
  gap: 16px;
  align-items: center;
  width: 546px;
}
.get-started-card-image-div p {
  font-size: 24px;
  font-weight: 700;
  line-height: 31.2px;
  letter-spacing: 0.5px;
  text-align: left;
}
.get-started-card-image-div img {
  height: 40px;
  width: 40px;
}
.get-started-card-extra-text {
  font-size: 16px;
  font-weight: 400;
  line-height: 27.2px;
  letter-spacing: 0.5px;
  text-align: left;
}
.button-get-started {
  background-color: var(--s-pink-normal);
  color: var(--state-white);
  width: 546px;
  padding: 16px 24px 16px 24px;
  gap: 10px;
  border-radius: 40px;

  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
}
.button-get-started a {
  outline: none;
  color: var(--state-white);
  text-decoration: none;
}
.main-three-header p {
  font-size: 36px;
  font-weight: 700;
  line-height: 52px;
  letter-spacing: 0.5px;
  color: var(--s-green-darker);
}
.main-three-header {
  margin-top: 80px;
  display: flex;
  justify-content: center;
  margin-bottom: 32px;
}
.footer {
  padding: 52px 134px 59px 157px;
  background-color: var(--s-green-normal);
}
.footer-content-container {
  display: flex;
  justify-content: space-between;
}
.social-media-links {
  display: flex;
  justify-content: flex-start;
  gap: 20.58px;
}
.social-media-links a {
  outline: none;
}
.logoandsocialmedia {
  display: flex;
  flex-direction: column;
}
.footer-content-container > div {
  max-width: 220px;
}
.white-text {
  color: #ffffff;
  font-size: 15.22px;
  font-weight: 400;
  line-height: 25.87px;
  letter-spacing: 0.5434070825576782px;
  text-align: justified;
}
.footer-header-text {
  font-size: 20px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: 0.5px;
  color: var(--state-white);
}
.footer-section > div:first-child {
  margin-bottom: 16px;
}
.footer-section > div:not(:first-child) {
  margin-bottom: 8px;
}
.footer-section > div:not(:first-child) a {
  font-size: 16px;
  font-weight: 400;
  line-height: 27.2px;
  letter-spacing: 0.5px;
  color: var(--state-white);
  text-decoration: none;
}

/* .dropdown {
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-top: 10px;
  max-height: 200px;
  overflow-y: auto;
}
.dropdown ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.dropdown li {
  padding: 10px;
  cursor: pointer;
}
.dropdown li:hover {
  background-color: #f0f0f0;
} */
.dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-top: 5px;
  background-color: white;
  z-index: 10000;
  max-height: 200px;
  overflow-y: auto;
}
.dropdown ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.dropdown li {
  padding: 10px;
  cursor: pointer;
}
.dropdown li:hover {
  background-color: #f0f0f0;
}
.ft {
  font-family: Inter;
  font-size: 10px;
  font-weight: 400;
  line-height: 11.9px;
  letter-spacing: 0.25px;
  text-align: center;
  color: #ffffff;
  margin-top: 36px;
}
.footer-header-text {
  font-size: 20px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: 0.5px;
  color: var(--state-white);
}
.footer-section > div:first-child {
  margin-bottom: 16px;
}
.footer-section > div:not(:first-child) {
  margin-bottom: 8px;
  display: flex;
  flex-direction: column;
}
.footer-section > div:not(:first-child) a {
  font-size: 16px;
  font-weight: 400;
  line-height: 27.2px;
  letter-spacing: 0.5px;
  color: var(--state-white);
  text-decoration: none;
}

@media screen and (max-width: 800px) {
  .links {
    align-items: center;
    gap: 5px !important;
  }
  .links a {
    text-decoration: none;

    font-size: 10px;
  }
  .main-one {
    padding: 0px 20px 0px 20px;
    display: flex;
    /* align-items: flex-end; */
    flex-direction: column;
    justify-content: center;
    /* background-color: #fff8f9; */
  }
  .navbar {
    padding: 0px 20px 0px 20px;
    flex-direction: row;
    justify-content: space-between;
  }
  .main-logo {
    width: 100px !important;
    height: 50px !important;
  }
  .footer {
    padding: 20px;
    background-color: var(--s-green-normal);
    width: 100%;
  }
  .footer-content-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  .button-get-started {
    width: 350px !important;
  }
  .join-now {
    width: 80px !important;
    font-size: 12px !important;
    height: 40px;
  }
  .links {
    align-items: center;
    gap: 7px !important;
  }

  .ft {
    margin-top: 16px;
  }
  .logoandsocialmedia {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .footer-content-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  .social-media-links {
    justify-content: center;
  }
  .footer-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .footer-content-container > div {
    max-width: 320px;
  }
  .footer-section {
    width: 100%;
  }
  .small {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}
</style>