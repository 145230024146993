<template>
  <DashboardLayout>
    <LoadingSpinner :isLoading="isLoading" />
    <div class="overall-container">
      <div class="main-container">
        <div class="content">
          <div class="jobs-div">
            <h2>Hired</h2>
          </div>
          <div class="content-main">
            <div class="tabs">
              <div
                class="single-tab"
                :class="{ active: currentTab === 'Current' }"
                @click="setTab('Current')"
              >
                <p>Current</p>
              </div>
              <div
                class="single-tab"
                :class="{ active: currentTab === 'Previous' }"
                @click="setTab('Previous')"
              >
                <p>Previous</p>
              </div>
            </div>

            <!-- Check if no jobs for the current tab -->
            <div v-if="noJobs" class="no-archived-container">
              <div class="no-archived">
               
                  <!-- SVG content here -->
                        <svg
                        width="125"
                        height="107"
                        viewBox="0 0 125 107"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M84.6783 4.47897C84.6783 4.47897 67.5568 26.1989 40.1618 13.2436C12.7668 0.288249 -14.6278 35.3442 8.96193 56.6832C32.5516 78.0222 9.46734 89.7478 25.4935 102.826C43.7082 117.69 59.5663 87.5485 74.0246 94.4075C105.935 109.545 129.575 103.553 124.248 86.0243C114.898 55.2554 102.941 53.2537 112.073 37.2495C121.205 21.2453 101.051 -12.0315 84.6783 4.47897Z"
                          fill="#FCE0E5"
                        />
                        <path
                          d="M94.2 25.5789H90.2895C85.9875 25.5789 82.5 22.0915 82.5 17.7895C82.5 13.4663 79.029 10 74.7 10H51.3C46.971 10 43.5 13.4663 43.5 17.7895C43.5 22.0915 40.0125 25.5789 35.7105 25.5789H31.8C27.471 25.5789 24 29.0453 24 33.3684V76.2105C24 80.5337 27.471 84 31.8 84H94.2C98.529 84 102 80.5337 102 76.2105V33.3684C102 29.0453 98.529 25.5789 94.2 25.5789ZM51.3 21.6842C51.3 19.5332 53.0437 17.7895 55.1947 17.7895H70.8053C72.9563 17.7895 74.7 19.5332 74.7 21.6842C74.7 23.8352 72.9563 25.5789 70.8053 25.5789H55.1947C53.0437 25.5789 51.3 23.8352 51.3 21.6842ZM94.2 72.3158C94.2 74.4668 92.4563 76.2105 90.3053 76.2105H35.6947C33.5437 76.2105 31.8 74.4668 31.8 72.3158C31.8 70.1648 33.5437 68.4211 35.6947 68.4211H90.3053C92.4563 68.4211 94.2 70.1648 94.2 72.3158ZM94.2 46.7368C94.2 52.2597 89.7229 56.7368 84.2 56.7368H41.8C36.2771 56.7368 31.8 52.2597 31.8 46.7368V39.2184C31.8 35.9876 34.4191 33.3684 37.65 33.3684H39.6053C41.7563 33.3684 43.5 35.1122 43.5 37.2632C43.5 39.4142 45.249 41.1579 47.4 41.1579C49.551 41.1579 51.3 39.4142 51.3 37.2632C51.3 35.1122 53.0437 33.3684 55.1947 33.3684H70.8053C72.9563 33.3684 74.7 35.1122 74.7 37.2632C74.7 39.4142 76.449 41.1579 78.6 41.1579C80.751 41.1579 82.5 39.4142 82.5 37.2632C82.5 35.1122 84.2437 33.3684 86.3947 33.3684H88.35C91.5809 33.3684 94.2 35.9876 94.2 39.2184V46.7368Z"
                          fill="#17865F"
                        />
                     
                </svg>
                <p>You have no hired Caregiver listed</p>
              </div>
            </div>

            <!-- Show jobs if available -->
            <div v-else class="jobs-list">
              <div
                class="jobs-list-item"
                v-for="jobs in displayedJobs"
                :key="jobs.id"
              >
                <div class="jobs-list-item-first-div">
                  <div>
                    <p class="sub-text">
                      Caregiver Name: {{ jobs.care_giver.user.last_name }}, {{ jobs.care_giver.user.first_name }}
                    </p>
                  </div>
                  <p class="sub-text">{{ jobs.care_giver.category[0].name }}</p>
                </div>
                <div>
                  <p class="main-text">{{ jobs.other_requirement }}</p>
                </div>
                <div>
                  <router-link
                    :to="`view-hire/${jobs.id}`"
                    class="view-edit"
                  >View hire</router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </DashboardLayout>
</template>



<script>
import { ref, onMounted, computed } from "vue";

import DashboardLayout from "@/views/Dashboard/Caregiver/IndexPage.vue";
import Request from "@/utils/apiclient.js";
import LoadingSpinner from "@/components/LoadingSpinner.vue";

export default {
  name: "CareSeekerHired",
  components: { DashboardLayout, LoadingSpinner },
  setup() {
    const isLoading = ref(true);
    const currentTab = ref("Current");
    const careSeekerJobs = ref([]);

    const setTab = (tab) => {
      currentTab.value = tab;
    };

    // Fetch hired jobs
    const fetchHired = () => {
      const request = new Request("/hire/");
      request.get((res, err) => {
        isLoading.value = false;
        if (err) {
          console.error("Error:", err);
        } else {
          careSeekerJobs.value = [...res.results];
        }
      });
    };

    // Computed properties to filter jobs based on the current tab
    const currentJobs = computed(() => {
      return careSeekerJobs.value.filter((job) => job.status !== 3);
    });

    const previousJobs = computed(() => {
      return careSeekerJobs.value.filter((job) => job.status === 3);
    });

    // Determine which jobs to show based on the active tab
    const displayedJobs = computed(() => {
      return currentTab.value === "Current" ? currentJobs.value : previousJobs.value;
    });

    // Check if there are no jobs in the current tab (Current or Previous)
    const noJobs = computed(() => {
      return displayedJobs.value.length === 0;
    });

    onMounted(() => {
      fetchHired();
    });

    return {
      setTab,
      currentTab,
      careSeekerJobs,
      displayedJobs, // Expose the filtered jobs
      noJobs, // Expose the "no jobs" check for the active tab
      isLoading,
    };
  },
};

</script>

<style scoped>
.overall-container {
  display: flex;
  width: 100%;
  justify-content: center;
}
.main-container {
  display: flex;
  padding: 32px 0px 500px 28px;
}
.content {
  width: 967px;
}
.jobs-div {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.tabs {
  display: flex;
  justify-content: flex-start;
  margin-top: 42px;
}
.tabs p {
  color: #0a3828;
  font-size: var(--font-body-normal-regular-size);
  line-height: var(--font-body-normal-regular-line-height);
  font-weight: var(--font-weight-regular);
}
.single-tab {
  padding: 0px 20.5px 4px 20.5px;
  cursor: pointer;
}
.active {
  border-bottom: 3px solid var(--s-pink-normal);
}
.active p {
  color: var(--s-pink-normal) !important;
  font-weight: var(--font-weight-bold) !important;
}
.jobs-list {
  padding-top: 16px;
}
.jobs-list-item {
  margin-bottom: 16px;
  border: 1px solid var(--s-green-light-active);
  border-radius: 12px;
  padding: 20px 16px;
}
.jobs-list-item-first-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.jobs-list-item-first-div div:first-child {
  display: flex;
  flex-direction: column;
}
h4 {
  margin: 0px 0px 4px 0px;
}
.view-edit {
  color: var(--s-green-normal-active);

  text-decoration: none;
}
.main-text {
  margin-bottom: 20px;
  font-size: 14px;
  font-weight: 400;
  line-height: 23.8px;
  letter-spacing: 0.5px;
  text-align: justified;
}
.sub-text {
  font-size: 12px;
  font-weight: 400;
  line-height: 20.4px;
  letter-spacing: 0.5px;
  text-align: left;
}
h2 {
  margin: 0px;

  font-size: 28px;
  font-weight: 700;
  line-height: 36.4px;
  letter-spacing: 0.5px;
  text-align: center;
  display: flex;
  align-items: center;
}
.no-archived-container {
  display: flex;
  justify-content: center;
  padding-top: 49px;
}
.no-archived {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 575px;
  background-color: #fff;
  padding-top: 59px;
  padding-bottom: 59px;
  flex-direction: column;
}
.no-archived svg {
  margin-bottom: 10px;
}
.no-archived p {
  margin-bottom: 16px;
  font-size: 20px;
  font-weight: 400;
  line-height: 34px;
  letter-spacing: 0.5px;
  text-align: center;
}
@media (max-width:800px) {
  .content{
    width: 320px;
  }
}
</style>
