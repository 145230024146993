<!-- ReusableTable.vue -->
<template>
  <div class="table-wrapper">
    <LoadingSpinner :isLoading="isLoading" />
      
    <table class="data-table">
      <thead>
        <tr>
          <th v-for="col in columns" :key="col.field">{{ col.label }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(row, rowIndex) in paginatedData" :key="rowIndex">
          <td v-for="col in columns" :key="col.field">
            <template v-if="$slots['cell-' + col.field]">
              <slot :name="'cell-' + col.field" :row="row" :index="rowIndex"></slot>
            </template>
            <template v-else>
              <span v-if="col.render">{{ col.render(row) }}</span>
              <span v-else>{{ getNestedValue(row, col.field) }}</span>
            </template>
          </td>
        </tr>
      </tbody>
    </table>
    <!-- Pagination controls -->
    <div class="pagination">
      <button @click="prevPage" class="prev-button" :disabled="page === 1">Previous</button>
      <span>Page {{ page }} of {{ totalPages }}</span>
      <button @click="nextPage" class="next-button" :disabled="page === totalPages">Next</button>
    </div>
  </div>
</template>

<script>
import { ref, computed, watch, onMounted } from 'vue';
import LoadingSpinner from '@/components/LoadingSpinner.vue';
import Request from '@/utils/apiclient.js';

export default {
  name: 'UniversalTable',
  components: {
    LoadingSpinner,
  },
  props: {
    endpoint: {
      type: String,
      required: true,
    },
    columns: {
      type: Array,
      required: true,
    },
    pageSize: {
      type: Number,
      default: 10,
    },
    filters: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props) {
    const data = ref([]);
    const isLoading = ref(true);
    const page = ref(1);

    const totalPages = computed(() => {
      return Math.ceil(data.value.length / props.pageSize);
    });

    const paginatedData = computed(() => {
      if (!Array.isArray(data.value)) return [];
      const start = (page.value - 1) * props.pageSize;
      return data.value.slice(start, start + props.pageSize);
    });

    const prevPage = () => {
      if (page.value > 1) {
        page.value--;
      }
    };

    const nextPage = () => {
      if (page.value < totalPages.value) {
        page.value++;
      }
    };

    const fetchData = () => {
      isLoading.value = true;
      const request = new Request(props.endpoint);
      request.get((res, err) => {
        isLoading.value = false;
        if (err) {
          console.error("Error:", err);
          // Handle error
        } else {
          console.log("data", res);
          data.value = res;
        }
      });
    };

    onMounted(() => {
      fetchData();
    });

    watch(() => props.endpoint, () => {
      fetchData();
    });

    const getNestedValue = (obj, path) => {
      return path.split('.').reduce((o, key) => (o ? o[key] : ''), obj);
    };

    return {
      data,
      isLoading,
      page,
      totalPages,
      paginatedData,
      prevPage,
      nextPage,
      getNestedValue,
    };
  },
};
</script>
<style scoped>
table {
  width: 100%;
}
.table-wrapper {
  width: 100%;
}
.table-container {
  padding: 20px;
}

.filter-container {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.select-container {
  margin-bottom: 20px;
  display: flex;
  gap: 5px;
  flex-direction: column;
  width: 100%;
}

.select-container label {
  white-space: nowrap;
}

.data-table {
  width: 100%;
  table-layout: fixed; /* Ensure equal column width distribution */
  border-collapse: collapse;
}

.data-table th,
.data-table td {
  padding: 8px;
  /* border: 1px solid #ddd; */
}
.table-container {
  padding: 20px;
  width: 100%;
}

.data-table {
  width: 100%;
  border-collapse: collapse; /* Ensure borders are collapsed */
  /* table-layout: auto; */
  table-layout: fixed;
}

.data-table th,
.data-table td {
  padding: 10px 15px; /* Add consistent padding */
  text-align: left; /* Align text to the left */
}

.pagination {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.input-text {
  width: 150px;
  outline: none;
  transition: 0.2s;
  height: 44px;
  background-color: transparent;
  padding: 16px 10px 16px 10px;
  border-radius: 12px;
  box-sizing: border-box;
  border: 1px solid #979797;
}
.input-text::placeholder {
  color: #979797;
  font-size: 16px;
  font-weight: 400;
  line-height: 27.2px;
  letter-spacing: 0.5px;
  text-align: left;
}
.filter-div {
  display: flex;
  gap: 10px;
  align-items: center;
}
.next-button {
  width: 100px;
  background-color: var(--s-pink-normal);
  border: none;
  border-radius: 40px;
  color: white;
  font-size: 16px;
  cursor: pointer;
  margin-bottom: 10px;
  padding: 10px 20px 10px 20px;
}
.next-button:disabled {
  background-color: var(--state-grey);
  cursor: not-allowed;
  opacity: 0.2;
}
.base-button {
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  padding: 10px;
  font-size: 10px;

  border: 2px solid transparent;
  border-radius: 25px;
  cursor: pointer;
  box-sizing: border-box;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s, opacity 0.3s;
}

.base-button {
  background-color: var(--s-green-normal);
  color: var(--state-white);
}
.prev-button {
  width: 100px;
  background-color: var(--s-green-normal);
  border: none;
  border-radius: 40px;
  color: white;
  font-size: 16px;
  cursor: pointer;
  margin-bottom: 10px;
  padding: 10px 20px 10px 20px;
}
.prev-button:disabled {
  background-color: var(--state-grey);
  cursor: not-allowed;
  opacity: 0.2;
}
.page {
  display: flex;
  align-items: center;
  justify-content: center;
}
.select-container {
  width: 100%;
  max-width: 250px;
  height: 60px;

  position: relative;
}

.select-container select {
  width: 150px;
  padding: 12px 16px;
  font-size: 16px;
  border: 1px solid #979797;
  border-radius: 8px;
  background-color: white;
  color: #333;
  cursor: pointer;
  appearance: none; /* Remove default arrow */
  -webkit-appearance: none; /* Safari and Chrome */
  -moz-appearance: none; /* Firefox */
}

.select-container::after {
  content: ""; /* Custom arrow */
  font-size: 12px;
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  color: #333;
}

.select-container select:focus {
  outline: none;
  border-color: #1a73e8; /* Focus border color */
  box-shadow: 0 0 0 2px rgba(26, 115, 232, 0.2);
}

.select-container select option {
  padding: 10px;
  background-color: white;
  color: #333;
}
.all-filters-container {
  display: flex;
  justify-content: space-between;
}
.table-container {
  width: 100%;
}
.select-filter {
  display: flex;
  align-items: center;
  gap: 20px;
}
.the-td {
  position: relative;
}
.icon-dots {
  display: flex;
  padding-left: 12px;

  cursor: pointer;
}
.dropdown-menu {
  position: absolute;
  right: 60px; /* Align to the right edge of the icon-dots container */
  top: -30px;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 12px;
  box-shadow: 0px 2px 4px 0px #0000001a;
  padding: 10px;
  transform: translateY(100%); /* Position below the icon-dots */
  z-index: 10;
}

.dropdown-menu button {
  display: flex;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  padding: 4px 8px;
  width: 100%;
  text-align: left;
}

.dropdown-menu .icon {
  margin-right: 8px;
}
</style>
