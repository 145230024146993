<template>
  <DashboardLayout>
    <LoadingSpinner :isLoading="isLoading" />
    <div class="overall-container">
      <div class="main-container">
        <!-- <h1>Job ID: {{ id }}</h1> -->

        <!-- Other component content -->
        <div class="main-container-section">
          <div class="content-section">
            <h2>Jobs</h2>
            <div class="jobs-list-item-first-div">
              <h4>{{ jobDetails.category?.name }} needed</h4>

              <p class="sub-text">Posted 2 days ago</p>
            </div>
          </div>
          <div class="content-section">
            <p class="header-text">Job Description</p>
            <p>{{ jobDetails.other_requirement }}</p>
          </div>
          <div class="content-section">
            <p class="header-text">Category</p>
            <div class="jobs-list-item-first-div">
              <p class="sub-text">Posted 2 days ago</p>
            </div>
          </div>
          <div class="content-section">
            <p class="header-text">Jobs</p>
            <!-- <div class="jobs-list-item-type-of-care">
            <p> {{jobDetails.category?.name}}  </p>
            <div class="job-tag">
              <span
                v-for="sub_care in jobDetails.category?.sub_categories"
                :key="sub_care"
                >{{ sub_care.name }}</span
              >
            </div>
          </div> -->
            <div class="job-tag">
              <span
                v-for="subCategory in filteredSubCategories"
                :key="subCategory.id"
              >
                {{ subCategory.name }}
              </span>
            </div>
          </div>
          <div class="content-section">
            <p class="header-text">Rate</p>
            <div class="jobs-list-item-type-of-care">
              <!-- <h4>Senior Care Needed</h4> -->

              <div class="job-tag">
                <span>{{ jobDetails.budget }}</span>
              </div>
            </div>
          </div>

          <div class="content-section">
            <p class="header-text">Schedule</p>
            <div class="jobs-list-item-type-of-care">
              <!-- <h4>Senior Care Needed</h4> -->
              <div class="schedule-container">
                <div>
                  <svg
                    width="22"
                    height="24"
                    viewBox="0 0 22 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M19.2 2.4H18V0H15.6V2.4H6V0H3.6V2.4H2.4C1.068 2.4 0.012 3.48 0.012 4.8L0 21.6C0 22.92 1.068 24 2.4 24H19.2C20.52 24 21.6 22.92 21.6 21.6V4.8C21.6 3.48 20.52 2.4 19.2 2.4ZM19.2 21.6H2.4V9.6H19.2V21.6ZM19.2 7.2H2.4V4.8H19.2V7.2ZM7.2 14.4H4.8V12H7.2V14.4ZM12 14.4H9.6V12H12V14.4ZM16.8 14.4H14.4V12H16.8V14.4ZM7.2 19.2H4.8V16.8H7.2V19.2ZM12 19.2H9.6V16.8H12V19.2ZM16.8 19.2H14.4V16.8H16.8V19.2Z"
                      fill="#17865F"
                    />
                  </svg>
                </div>

                <div class="start-end">
                  <span>{{ jobDetails.days?.join(", ") || "" }}</span>

                  <span>{{ jobDetails.start_date }}</span>
                </div>
              </div>
              <!-- <div class="schedule-container">
              <div style="width: 22px; display: flex; justify-content: center">
                <svg
                  width="12"
                  height="10"
                  viewBox="0 0 12 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4 9.4L0 5.4L1.4 4L4 6.6L10.6 0L12 1.4L4 9.4Z"
                    fill="black"
                  />
                </svg>
              </div>

              <div class="start-end">
                <span>My start date is flexible</span>
              </div>
            </div> -->
            </div>
          </div>
          <div class="content-section">
            <p class="header-text">Preferences</p>
            <div class="jobs-list-item-type-of-care">
              <p>{{ jobDetails.work_preference }}</p>
            </div>
          </div>
          <!-- <div class="buttons">
            <BaseButton
              label="Edit job"
              backgroundColor="transparent"
              textColor="var(--s-green-normal-active)"
              height="60px"
              width="254px"
              borderColor="var(--s-green-normal-active)"
              @click="redirectToEditJob"
            />
            <BaseButton
              label="Close job"
              backgroundColor="transparent"
              textColor="var(--s-green-normal-active)"
              height="60px"
              width="254px"
              @click="showModal('close_job')"
            />
          </div> -->
        </div>
        <div>
          <GenericModal
            :isVisible="isMainModalVisible"
            :title="modalTitle"
            @close="hideModal"
            padding="72.5px 39.5px"
          >
            <template v-if="currentModal === 'close_job'">
              <!-- Preferences form content -->
              <p style="text-align: center">
                Are you sure you want to close this Job?.
              </p>
              <div class="modal-buttons">
                <BaseButton
                  label="Close Job"
                  backgroundColor="var(--state-error)"
                  textColor="var(--state-white)"
                  height="60px"
                  width="196px"
                  @click="hideModal"
                />
                <BaseButton
                  label="Cancel"
                  backgroundColor="transparent"
                  textColor="var(--s-green-normal-active)"
                  borderColor="var(--s-green-normal-active)"
                  height="60px"
                  width="196px"
                  @click="hideModal"
                />
              </div>
            </template>
          </GenericModal>
        </div>
      </div>
    </div>
  </DashboardLayout>
</template>

<script>
import { useRoute, useRouter } from "vue-router";
import GenericModal from "@/components/GenericModal.vue";
import { ref, onMounted, computed } from "vue";
import BaseButton from "@/components/BaseButton.vue";
import DashboardLayout from "@/views/Dashboard/Caregiver/IndexPage.vue";
import Request from "@/utils/apiclient.js";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
export default {
  name: "ViewEditJob",
  components: { BaseButton, GenericModal, DashboardLayout, LoadingSpinner },
  setup() {
    const isLoading = ref(true);
    const route = useRoute();
    const id = ref(route.params.id);
    const router = useRouter();
    const jobDetails = ref({
      // description:
      //   "Donec euismod lorem eget laoreet rutrum. Pellentesque vitae urna id urna ornare iaculis. Nulla facilisi. In hac habitasse platea dictumst. Nulla ac sapien sapien. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.Donec euismod lorem eget laoreet rutrum. Pellentesque vitae urna id urna ornare iaculis. Nulla facilisi. ",
      // type_of_care: "Senior Care",
      // sub_care_categories: [
      //   "Bathing / Dressing",
      //   "Dementia",
      //   "Home Health",
      //   "Live-in Home care",
      //   "Live-out Home Care",
      // ],
      // rate: "N5,000 / day",
      // start_date: "Starts: Thur. 9th April, 2024",
      // preferences: "Live in",
    });
    const isMainModalVisible = ref(false);
    const currentModal = ref("");
    const modalTitle = ref("");

    const getModalTitle = (modalType) => {
      switch (modalType) {
        case "close_job":
          return "Close Job";
        default:
          return "";
      }
    };
    const showModal = (modalType) => {
      console.log("clicked", modalType);
      currentModal.value = modalType;
      modalTitle.value = getModalTitle(modalType);
      isMainModalVisible.value = true;
    };

    const hideModal = () => {
      isMainModalVisible.value = false;
    };

    const handleConfirm = () => {
      hideModal();
    };

    const redirectToEditJob = () => {
      router.push({ name: "EditJob", params: { jobId: id.value } });
    };

    const fetchJob = () => {
      const request = new Request(`/job/${id.value}/`);
      request.get((res, err) => {
        isLoading.value = false;
        if (err) {
          console.error("Error:", err);
        } else {
          console.log("here is admin careseekers data", res);
          jobDetails.value = { ...res };

          console.log("jb", jobDetails.value);
        }
      });
    };
    onMounted(() => {
      fetchJob();
    });

    const filteredSubCategories = computed(() => {
      if (!jobDetails.value) return [];
      const selectedSubCategoryIds = jobDetails.value.sub_category;
      const availableSubCategories =
        jobDetails.value.category?.sub_categories || [];

      // Filter the subcategories to only include those whose IDs are in the selectedSubCategoryIds
      return availableSubCategories.filter((subCategory) =>
        selectedSubCategoryIds.includes(subCategory.id)
      );
    });
    return {
      id,
      jobDetails,
      showModal,
      handleConfirm,
      isMainModalVisible,
      modalTitle,
      currentModal,
      hideModal,
      redirectToEditJob,
      isLoading,
      filteredSubCategories,
    };
  },
};
</script>
<style scoped>
.overall-container {
  display: flex;
  width: 100%;
  justify-content: center;
}
.main-container {
  display: flex;
  width: 100%;
  padding: 33px 0px 200px 0px;
  width: 1184px;
  box-sizing: border-box;
}
.content-section {
  padding-bottom: 24px;
  margin-bottom: 24px;
  border-bottom: 1px solid #979797;
  width: 100%;
}
h2 {
  margin: 0px;

  font-size: 28px;
  font-weight: 700;
  line-height: 36.4px;
  letter-spacing: 0.5px;
  text-align: center;
  display: flex;
  align-items: center;
}
.jobs-list-item-first-div {
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  margin-top: 16px;
}
.sub-text {
  font-size: 12px;
  font-weight: 400;
  line-height: 20.4px;
  letter-spacing: 0.5px;
  text-align: left;
}
.main-container-section {
  display: flex;
  width: 839px;
  flex-direction: column;
}
h4 {
  font-size: 24px;
  font-weight: 700;
  line-height: 31.2px;
  letter-spacing: 0.5px;
  text-align: left;
  margin: 0px 0px 0px 0px;
}
.header-text {
  font-size: 16px;
  font-weight: 700;
  line-height: 27.2px;
  letter-spacing: 0.5px;
  margin-bottom: 16px;
  text-align: justified;
}
p {
  font-size: 16px;
  font-weight: 400;
  line-height: 27.2px;
  letter-spacing: 0.5px;
  text-align: left;
}
.job-tag {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.job-tag span {
  padding: 10px;
  gap: 8px;
  border-radius: 12px;
  background-color: #ececec;
  font-size: 12px;
  font-weight: 400;
  line-height: 20.4px;
  letter-spacing: 0.5px;
  text-align: left;
}
.jobs-list-item-type-of-care {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.schedule-container {
  display: flex;
  align-items: center;
  gap: 24px;
}
.start-end {
  display: flex;
  flex-direction: column;
  gap: 9px;
}
.content-section:last-child {
  border-bottom: 0px;
}
.buttons {
  display: flex;
  margin-top: 70px;
}
.modal-buttons {
  display: flex;
  gap: 8px;
  margin-top: 24px;
}

@media (max-width: 800px) {
.main-container-section{
  max-width: 320px;
}
.main-container{
  justify-content: center;
}
}
</style>
